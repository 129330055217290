/* jshint esversion: 6 */
import alertifyjs from '../../node_modules/alertifyjs/build/alertify.min.js';
require('../../node_modules/alertifyjs/build/css/alertify.min.css');
require('../../node_modules/alertifyjs/build/css/themes/default.min.css');

export default {
    message (text, options = {}, successCallback = null) {
        if (typeof successCallback !== 'function') {
            successCallback = function () {};
        }
        var defaultOptions = {
            duration: 2,
            position: 'center',
            type: 'success'
        };
        if (!options) {
            options = {};
        }
        options = Object.assign(defaultOptions, options);
        alertifyjs.notify(text, options.type, options.duration, successCallback);
    }
};
